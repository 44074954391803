<template>
  <!-- 商品信息展示 -->
  <div class="xm-goods-info">
    <div class="goods-img">
      <img :src="src | imgbaseurl" alt="" @error="handleImgError" />
    </div>
    <div class="goods-box">
      <div class="box-line goods-title">
        <div class="goods-text" :title="title">
          {{ title }}
        </div>
      </div>
      <div class="box-line goods-spec">
        <div class="goods-lab">商品规格：</div>
        <div class="goods-text" :title="spc">
          {{ spc }}
        </div>
      </div>
      <div class="box-line goods-id">
        <div class="goods-lab">商品ID：</div>
        <div class="goods-text">{{ item_id }}</div>
        <div class="iconfont copy" @click="copyText(item_id)">&#xe66c;</div>
      </div>
      <div class="box-line goods-label">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    // 标题
    title: {
      type: String,
      default: '',
    },
    // 规格
    spc: {
      type: String,
      default: '',
    },
    // 商品ID
    item_id: {
      type: String,
      default: '',
    },
    // 图片
    src: {
      type: String,
      default: '',
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.xm-goods-info {
  display: flex;
  width: 283px;

  .goods-img {
    flex-shrink: 0;
    margin-right: 10px;
    img {
      width: 66px;
      height: 66px;
    }
  }

  .goods-box {
    width: 100%;
    flex-grow: 1;
    min-width: 0;
    .box-line {
      display: flex;
      line-height: 22px;
      color: #333;
      font-size: 14px;

      .goods-lab {
        flex-shrink: 0;
      }
      .goods-text {
        max-width: 100%;
        width: fit-content;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .iconfont {
        margin-left: 4px;
        color: #ff7802;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
</style>
